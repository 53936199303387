$(function(){
    /*
        https://www.jqueryscript.net/animation/Smooth-Mouse-Wheel-Scrolling-Plugin-With-jQuery-easeScroll.html
        ===========================*/
    $("html").easeScroll({
        frameRate: 60,
        animationTime: 1000,
        stepSize: 90,
        pulseAlgorithm: 1,
        pulseScale: 8,
        pulseNormalize: 1,
        accelerationDelta: 20,
        accelerationMax: 1,
        keyboardSupport: true,
        arrowScroll: 50,
        touchpadSupport: true,
        fixedBackground: true
    });

    /* fix menu on mobile
    ====================================================*/
    let headerMain = $('#js-header-main');
    let headerMainHeight = headerMain.innerHeight();
    let categoriesWrap = $('#js-categories-wrap');
    let categoriesWrapHeight = categoriesWrap.innerHeight();
    let windowSize = 1120;
    let body = $('body');
    /*fix menu for mobile min 1120
    =======================================*/

    let isDesktop = !($(window).width() < windowSize),//объявляем переменную isDesktop
        scrollTop = $(window).scrollTop(),//объявляем переменную scrollTop

        forDesktop = function(){//объявляем функцию для мониторов
            scrollTop = $(window).scrollTop();//получаем скроллинг в браузере
            !categoriesWrap.is(":visible") && categoriesWrap.css({'display': 'none'});

            if(scrollTop < headerMainHeight + 10) {
                categoriesWrap.removeClass('fixed');
                categoriesWrap.css('display', 'block');
                body.css({
                    'paddingTop': 0
                });
            }

            if(scrollTop > headerMainHeight + 100) {
                categoriesWrap.addClass('fixed');
                categoriesWrap.css('display', 'block');
                body.css({
                    'paddingTop': 53
                });

                if(headerMain.hasClass('fixed')) {
                    headerMain.removeClass('fixed');
                }
            }
        };

    let forMobile = function(){

        scrollTop = $(window).scrollTop();
        categoriesWrap.is(":visible") && categoriesWrap.css({'display': 'none'});

        if(scrollTop < headerMainHeight + 10) {
            body.css({
                'paddingTop': 0
            });

            categoriesWrap.hide();

            headerMain.removeClass('fixed');
            categoriesWrap.hide();
        }

        if(scrollTop > headerMainHeight + 100) {
            headerMain.addClass('fixed');
            body.css({
                'paddingTop': 100
            });
        }
    };
    // Подписываемся на события
    $(window).on('resize scroll', function(e){
        //
        isDesktop = !($(window).width() < windowSize);
        //headerMainHeight = headerMain.innerHeight();
        //
        isDesktop ? forDesktop() : forMobile();
    });

    // Запустим
    isDesktop ? forDesktop() : forMobile();

    /* show search in header
    ====================================================*/
    $('#js-search-open').on('click', function(e){
        e.preventDefault();
        $('#js-search-bar-wrap').addClass('show');

        $('#js-search-close').on('click', function(e){
            e.preventDefault();
            $('#js-search-bar-wrap').removeClass('show');
        });
    });

    /* show menu
    ====================================================*/
    $('#js-menu-button__open').on('click', function(e){
        e.preventDefault();

        $('#js-menu-media').slideDown();
        categoriesWrap.slideDown();
        categoriesWrap.removeClass('fixed');

        $('#js-menu-button__close').css('display', 'block');
        $(this).hide();
    });


    /* close menu
    ====================================================*/
    $('#js-menu-button__close').on('click', function(e){
        e.preventDefault();

        $('#js-menu-media').slideUp();
        categoriesWrap.slideUp();
        categoriesWrap.removeClass('fixed');

        $('#js-menu-button__open').show();
        $(this).hide();
    });

    /* modals
    ====================================================*/
    let modalsRequest = $('#js-modals_request');
    let modalsTips = $('#js-modal_tips');
    let calcChoiceForm = $('#js-calc_choice-form');
    let choiceForm = $('#js-choice-form');
    let measureForm = $('#js-measure-form');

    /* deactivate submit
    ====================================================*/
    $('input[type="checkbox"]').on('click', function(){
        let form = $(this).closest('.b-form');
        let submit = form.find($('.b-form__submit'));

        if(!$(this).is(':checked')) {
            submit.prop('disabled', true);
            submit.addClass('disabled');
        } else {
            submit.prop('disabled', false);
            submit.removeClass('disabled');
        }
    });

    // open request
    $('.get-size').on('click', function(e){
        e.preventDefault();
        $('.overlay').fadeIn(300);
        $('.b-modal-request').fadeIn(600);
        $('body').css({
            'padddingRight': 14 + 'px',
            'overflow': 'hidden'
        });
    });

    //close request
    $('.close-modal').on('click', function(e){
        e.preventDefault();
        $('.overlay').fadeOut(300);
        $('.b-modal-request').fadeOut('600');
        $('.b-modal-tips').fadeOut('600');
        $('body').css({
            'padddingRight': 0,
            'overflow': 'scroll'
        });
    });

    //close thanks
    $('.b-thanks .b-btn').on('click', function(e){
        e.preventDefault();
        $('.b-thanks').fadeOut(600);
        $('.overlay').fadeOut(300);
        $('body').css({
            'padddingRight': 0,
            'overflow': 'scroll'
        });
    });

    //close thanks
    $('.overlay').on('click', function(e){
        e.preventDefault();
        $('.overlay').fadeOut(300);
        $('.b-modal-request').fadeOut('600');
        $('.b-modal-tips').fadeOut('600');
        $('.b-thanks').fadeOut('600');
        $('body').css({
            'padddingRight': 0,
            'overflow': 'scroll'
        });
    });

    /* form for block measure on index
    https://github.com/RobinHerbots/Inputmask#clearincomplete
    https://jqueryvalidation.org/
    ====================================================*/
    $('#js-measure-form .mask').inputmask("+7 (999) 999-9999", {"clearIncomplete": true});

    measureForm.validate({
        rules: {
            input_name: {
                required: true
            },
            input_phone: {
                required: true
            }
        },
        messages: {
            input_name: {
                required: 'Поле обязательно!'
            },
            input_phone: {
                required: 'Поле обязательно!'
            }
        },
        invalidHandler: function(event, validator){
            $(this).find('input').attr('placeholder', '');
        },
        submitHandler: function(form){
            $.ajax({
                type: 'POST',
                url: 'order.php',
                data: $(form).serializeArray(),
                dataType: 'json',
                success: function(data){
                    $('#order-sent-phone').html(' ' + $('#inputPhone').val());
                    $('#order').css('display', 'none');
                    $('#order-sent').css('display', 'block');
                },
                error: function(xhr, str){
                    alert('Возникла ошибка: ' + xhr.responseCode);
                }
            });
            $(form)[0].reset();
            $('.b-thanks').fadeIn(600);
        },
        focusCleanup: true,
        focusInvalid: false,
        onfocusout: false

    });

    /* form for block choice on index
    ====================================================*/
    $('#js-choice-form .mask').inputmask("+7 (999) 999-9999", {"clearIncomplete": true});


    choiceForm.validate({
        rules: {
            input_name: {
                required: true
            },
            input_phone: {
                required: true
            }
        },
        messages: {
            input_name: {
                required: 'Поле обязательно!'
            },
            input_phone: {
                required: 'Поле обязательно!'
            }
        },
        invalidHandler: function(event, validator){
            $(this).find('input').attr('placeholder', '');
        },
        submitHandler: function(form){
            $.ajax({
                type: 'POST',
                url: 'order.php',
                data: $(form).serializeArray(),
                dataType: 'json',
                success: function(data){
                    $('#order-sent-phone').html(' ' + $('#inputPhone').val());
                    $('#order').css('display', 'none');
                    $('#order-sent').css('display', 'block');
                },
                error: function(xhr, str){
                    alert('Возникла ошибка: ' + xhr.responseCode);
                }
            });

            $(form)[0].reset();
            $('.b-thanks').fadeIn(600);
        },
        focusCleanup: true,
        focusInvalid: false,
        onfocusout: false

    });

    /* form for block choice on index
    ====================================================*/
    $('#js-calc_choice-form .mask').inputmask("+7 (999) 999-9999", {"clearIncomplete": true});


    calcChoiceForm.validate({
        rules: {
            input_name: {
                required: true
            },
            input_phone: {
                required: true
            }
        },
        messages: {
            input_name: {
                required: 'Поле обязательно!'
            },
            input_phone: {
                required: 'Поле обязательно!'
            }
        },
        invalidHandler: function(event, validator){
            $(this).find('input').attr('placeholder', '');
        },
        submitHandler: function(form){
            $.ajax({
                type: 'POST',
                url: 'order.php',
                data: $(form).serializeArray(),
                dataType: 'json',
                success: function(data){
                    $('#order-sent-phone').html(' ' + $('#inputPhone').val());
                    $('#order').css('display', 'none');
                    $('#order-sent').css('display', 'block');
                },
                error: function(xhr, str){
                    alert('Возникла ошибка: ' + xhr.responseCode);
                }
            });

            $(form)[0].reset();
            $('.b-thanks').fadeIn(600);
        },
        focusCleanup: true,
        focusInvalid: false,
        onfocusout: false
    });

    /* form for modal request form
    ====================================================*/
    $('#js-modals_request .mask').inputmask("+7 (999) 999-9999", {"clearIncomplete": true});

    modalsRequest.validate({
        rules: {
            input_name: {
                required: true
            },
            input_phone: {
                required: true
            }
        },
        messages: {
            input_name: {
                required: 'Поле обязательно!'
            },
            input_phone: {
                required: 'Поле обязательно!'
            }
        },
        submitHandler: function(form){
            $.ajax({
                type: 'POST',
                url: 'order.php',
                data: $(form).serializeArray(),
                dataType: 'json',
                success: function(data){
                    $('#order-sent-phone').html(' ' + $('#inputPhone').val());
                    $('#order').css('display', 'none');
                    $('#order-sent').css('display', 'block');
                },
                error: function(xhr, str){
                    alert('Возникла ошибка: ' + xhr.responseCode);
                }
            });

            $(form)[0].reset();
            $('.b-modal-request').fadeOut(200);
            $('.b-thanks').fadeIn(600);
        },
        invalidHandler: function(event, validator){
            $(this).find('input').attr('placeholder', '');
        },
        focusCleanup: true,
        focusInvalid: false
    });

    /* modal tips
    ====================================================*/


    modalsTips.validate({
        rules: {
            input_email: {
                required: true,
                email: true
            }
        },
        messages: {
            input_email: {
                required: 'Поле обязательно!',
                email: 'Введите email!'
            }
        },
        submitHandler: function(form){
            $.ajax({
                type: 'POST',
                url: 'order.php',
                data: $(form).serializeArray(),
                dataType: 'json',
                success: function(data){
                    $('#order-sent-phone').html(' ' + $('#inputPhone').val());
                    $('#order').css('display', 'none');
                    $('#order-sent').css('display', 'block');
                },
                error: function(xhr, str){
                    alert('Возникла ошибка: ' + xhr.responseCode);
                }
            });

            $(form)[0].reset();
            $('.b-modal-tips').fadeOut(200);
            $('.b-thanks').fadeIn(600);
        },
        invalidHandler: function(event, validator){
            $(this).find('input').attr('placeholder', '');
        },
        focusCleanup: true,
        focusInvalid: false,
        onfocusout: false
    });

    /*function callObj(dataObj){
        $.ajax({
            type: 'POST',
            url: 'order.php',
            data: dataObj,
            dataType: 'json',
            success: function(data){
                $('#order-sent-phone').html(' ' + $('#inputPhone').val());
                $('#order').css('display', 'none');
                $('#order-sent').css('display', 'block');
            },
            error: function(xhr, str){
                alert('Возникла ошибка: ' + xhr.responseCode);
            }
        });
    }

    function call(){
        let dataArray = $('#order-' + id).serializeArray();
        let dataObj = {};

        $(dataArray).each(function(i, field){
            dataObj[field.name] = field.value;
        });

        callObj(dataObj);
    }*/
});